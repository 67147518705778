<template>
  <v-sheet>
    <component :is="currentRole" />
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/i18n";
export default {
  name: "Recap",
  metaInfo: {
    title: i18n.t("routes.recap"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    RecapAdmin: () => import("./RecapAdmin"),
    RecapTeacher: () => import("./RecapTeacher"),
    RecapStudent: () => import("./RecapStudent")
  },
  computed: mapGetters(["g_privilages_user"]),
  created() {
    const showRoleType = [];
    this.tabCode.map(d => {
      showRoleType.push(this.g_privilages_user.includes(d));
    });
    if (this.$store.getters.g_role_type === "STUDENT") {
      this.currentRole = "RecapStudent";
    } else if (showRoleType.includes(true)) {
      this.currentRole = "RecapAdmin";
    } else {
      this.currentRole = "RecapTeacher";
    }
  },
  data() {
    return {
      currentRole: "RecapAdmin",
      tabCode: ["RRC01", "RRC02", "RRC03"]
    };
  }
};
</script>
